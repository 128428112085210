import { OktaAuth } from '@okta/okta-auth-js';
import * as config from '../config';
import SignedUrlResponse from '../models/SignedUrlResponse';
import { fetch } from '../util/fetch';
import { ThunkAction } from '../store/shared/types';
import { getErrorMessage } from '../util/getErrorMessage';
import { toast } from 'react-toastify';

/**
 * Call Lambda to get signed aws console url
 * @param auth
 * @param accountId
 * @param role
 * @param destination
 * @param duration
 */
export const fetchSignedLoginUrl = (
  auth: OktaAuth,
  accountId: string,
  role: string,
  destination: string,
  duration: string,
  changeNumber?: string,
  primaryCINumber?: string,
  description?: string
): ThunkAction<SignedUrlResponse> => async (_dispatch) => {
  // TODO: this whole thunk is very strange and should do the redirecting itself, rather than returning a value for a component to handle - BW

  let accessToken: string | undefined;
  try {
    accessToken = auth.getAccessToken();
    if (!accessToken) {
      throw new Error('access token is undefined');
    }
  } catch (e) {
    const reason = `An error occured when retrieving the stored access token: ${getErrorMessage(
      e
    )}`;
    toast(reason, { type: 'error' });
    throw e;
  }

  let response: Response;
  const body = JSON.stringify({
    accountId,
    role,
    destination,
    duration,
    ...(changeNumber && { changeNumber }),
    ...(primaryCINumber && { primaryCINumber }),
    ...(description && { description }),
  });

  try {
    response = await fetch(
      `${config.default.service.url}${config.default.service.clickThruAction.path}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Api-Key': config.default.service.apiKey,
          'X-Okta-Auth': accessToken,
          'Content-Type': 'application/json',
        },
        body,
      }
    );
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    console.log(errorMessage);
    return {
      statusCode: 500,
      errorMessage: errorMessage,
    };
  }

  const res = await response.json();

  if (!response.ok) {
    if (response.status === 400) {
      const accountRoleIndex = res.errorMessage.indexOf('AccountRole:');
      if (accountRoleIndex !== -1) {
        const accRoleNum = res.errorMessage.substring(accountRoleIndex + 13);
        if (accRoleNum) {
          return {
            accountRole: accRoleNum,
            errorMessage: res.errorMessage,
            statusCode: response.status,
          };
        }
        return {
          statusCode: response.status,
          errorMessage: res.errorMessage.substring(0, accountRoleIndex),
        };
      }
      return {
        statusCode: response.status,
        errorMessage: res.errorMessage,
      };
    } else if (response.status === 500) {
      return {
        statusCode: response.status,
        errorMessage: res.errorMessage,
      };
    }
  }

  return {
    statusCode: response.status,
    signedUrl: res.signedUrl,
  };
};
